@import '../../variables.scss';

.personBallotCounterContainer {
	background-color: $white;
	border-bottom: 1px solid $grey_00;
	height: 62px;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.leftCounter,
.rightCounter {
	color: $blue_0f;
	padding: 10px;
	&:hover {
		cursor: pointer;
	}
}

.counterInvisible {
	visibility: hidden;
}

.personBlockContainer {
	color: $grey_72;
	display: grid;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	grid-template-columns: 1fr 48px 56px 80px;
	justify-content: space-around;
	margin: 8px 0 8px 0;
	:first-child {
		padding-right: 8px;
	}
	span:first-child {
		font-weight: bold;
	}
}

.blockIconContainer {
	display: flex;
}

.blockPersonAnswerDiv {
	background-color: $white;
	min-height: 48px;
	margin: 8px 0 8px 0;
}

.personAnswerContainer {
	box-shadow: 0px 2px 4px #00000029;
	display: grid;
	grid-template-columns: 1fr 48px 48px 48px;
	margin: 12px 0;
	.personAnswerTextDiv,
	.personAnswerCheckIconDiv {
		align-items: center;
		background-color: $grey_f7;
		border-bottom: 1px solid $grey_cf;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		min-height: 48px;
		&.button {
			color: $blue_18;
			font-size: larger;
		}
		&.button.personAnswerYes {
			font-size: x-large;
		}
		&.personAnswerYes.personAnswerSelected {
			background-color: $blue_18;
			color: $white;
		}
		&.personAnswerAbstent.personAnswerSelected {
			background-color: $result_purple;
			color: $white;
		}
		&.personAnswerNo.personAnswerSelected {
			background-color: $result_red;
			color: white;
		}
		&.disabled {
			color: $grey_c2;
			cursor: default;
		}
	}
	.personAnswerCheckIconDiv {
		cursor: pointer;
	}

	& > div:nth-child(odd) {
		background-color: $white;
	}

	.personAnswerTextDiv {
		justify-content: start;
		padding: 12px;
	}
}

.nextBallotContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 24px 0;
	button {
		all: unset;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 16px;
		color: $blue_18;
		&:hover {
			cursor: pointer;
		}
		&.disabled {
			cursor: default;
			color: $grey_c2;
		}
	}
}

.ballotContainerEnter {
	height: 0px;
	opacity: 0;
}
.ballotContainerEnterActive {
	height: 12px;
	opacity: 0.5;
}
.ballotContainerEnterDone {
	height: 24px;
	opacity: 1;
}
.ballotContainerExit {
	height: 24px;
	opacity: 1;
}
.ballotContainerExitActive {
	height: 12px;
	opacity: 0.5;
}
.ballotContainerExitDone {
	height: 0px;
	opacity: 0;
}
