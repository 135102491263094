.mainDiv{
    height: 100%;
  }
  

  .responsiveContainer {
    display: flex; 
    flex-direction: row;
    align-items: flex-start; 
    justify-content: center;
    width: 100%; 
    height: 100%;
    margin-bottom: 20px;
    //height: 700px !important;
   
  }


  .scrollableLegend {
    display: flex; 
    flex-direction: row;
    align-items: flex-start; 
    justify-content: center;
    width: 100%; 
   
  }

  
  
  .recharts-wrapper{
    width: 100% !important;
  }
  
  .recharts-legend-wrapper{
    height: 100% !important;
  }
  
  @media screen and (max-width: 770px) {

    
  .responsiveContainer {
    display: flex; 
    flex-direction: column;
    align-items: flex-start; 
    justify-content: flex-start;
    width: 100%; 
    height: 100%;
    //height: 700px !important;
   
  }

  .scrollableLegend{
    left: 5px;
  }

  .recharts-legend-wrapper{
    left: 6% !important;
    top: 10%;
  }
  .recharts-default-legend{
    padding: 20px 10px 70px 10px !important;
  }
  
  }

  @media screen and (max-width: 500px){
    .scrollableLegend{
      //min-height: calc(var(--vh, 1vh) * 80 - 88px - 15px);
    }
  }
  
  @media screen and (max-width: 436px) {

    .recharts-legend-wrapper {
        width: 260px !important  
    }

    .recharts-wrapper > svg {
      position: relative !important;
      height: 80% !important;
      right: 90%;
      width: 160% !important;
     } 

  }


  @media screen and (max-height: 900px) {
    .scrollableLegend{
      //min-height: calc(var(--vh, 1vh) * 80 - 88px - 15px);
    }
  }