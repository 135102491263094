@import '../../variables.scss';

.hintTexts {
	.hintText {
		color: $black_43;
		display: block;
		padding-bottom: 10px;
		& + .hintText {
			padding-top: 14px;
		}
		&:last-child {
			padding-bottom: 20px;
		}

		@include for-phone {
			text-align: center;
		}
	}
	padding: 0 16px;
}