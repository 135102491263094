@import "../../variables.scss";


.ratingBallotCounterContainer {
	background-color: $white;
	border-bottom: 1px solid $grey_00;
	height: 62px;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.leftCounter,
.rightCounter {
	color: $blue_0f;
	padding: 10px;
	&:hover {
		cursor: pointer;
	}
}

.counterInvisible {
	visibility: hidden;
}
.ratingAnswerStarsContainer {
    margin-left: 12px;
}

.star {
    color: $blue_18;
    font-size: 24px;
}
.ratingAnswerContainer {
    background-color: $white;
    border-bottom: 1px solid $grey_00;
    padding: 4px;
    min-height: 62px;
}

.ratingFormCalloutContainer {
	display: flex;
	font-size: 12px;
	padding: 8px;
	font-weight: bold;
	color: #727272;
    margin: 8px 0 48px 12px;
    margin-top: 8px;
	:first-child {
		padding-right: 8px;
	}
    i{
        font-size: 20px;
    }
}

.ratingAnswerTextDiv {
    margin-left: 12px;
    display: flex;
}
.questionText {
    margin: 0 0 12px 8px;
	font-weight: 600;
    font-size: 12px;
    color: $grey_72;
    line-height: 24px;
}

button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 32px;
    padding-top: 0;
    height: 48px;
    width: 42px;
}

.nextBallotContainer {
	align-items: center;
	color: $blue_18;
	cursor: pointer;
	display: flex;
	font-size: 16px;
	font-weight: 600;
	justify-content: center;
	margin: 32px 0;
	text-transform: uppercase;
	button {
		all: unset;
		&.disabled {
			color: $grey_c2;
			cursor: default;
		}
	}
}

.ballotContainerEnter {
	height: 0px;
	opacity: 0;
}
.ballotContainerEnterActive {
	height: 12px;
	opacity: 0.5;
}
.ballotContainerEnterDone {
	height: 24px;
	opacity: 1;
}
.ballotContainerExit {
	height: 24px;
	opacity: 1;
}
.ballotContainerExitActive {
	height: 12px;
	opacity: 0.5;
}
.ballotContainerExitDone {
	height: 0px;
	opacity: 0;
}




