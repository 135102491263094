@import '../../variables.scss';
.personHintRow {
	background-color: $warning-bg-color;
	border-style: solid;
	border-color: $warning-border-color;
	border-width: 1px 0;
	height: 54px;

	display: flex;
	align-items: center;
	gap: 8px;

	padding-left: 12px;

	font-size: 12px;
    font-weight: 600;
	color: $warning-icon-color;

	position: sticky;
	top: 0;

	.icon {
		font-size: 20px;
	}
	@include for-desktop {
		border-width: 1px;
	}
}
