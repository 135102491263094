.toggleableIcon {
	align-items: center;
	display: flex;
	height: 20px;
	justify-content: flex-start;
	margin-right: 10px;
	white-space: nowrap;

	> * {
		margin-right: 5px;
	}

	span {
		font-size: 14px;
	}

	i {
		font-size: 14px;
	}
}
