@import "../../../variables.scss";

.container {
  align-items: center;
  background-color: #bce4e8;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  gap: 6px;
  margin: 0;
  padding: 4px 12px;
  width: fit-content;
}
