@import '../../variables.scss';
.resultGraph {
	padding-top: 14px;
	font-size: 14px;
	color: $black_43;
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	p {
		align-items: center;
		display: flex;
		flex-grow: 1;
	}
	.bar {
		background: $blue_0f;
		border-radius: 5px;
		display: block;
		height: 10px;
		padding: 0 8px;
		transition: width ease-in-out 1s;
		width: 0;
	}
	.scale {
		font-weight: 400;
		line-height: 26px;
		padding-left: 10px;
	}
	.value {
		font-weight: 500;
	}

	.items {
		.itemAppear {
			.bar {
				width: 0 !important;
			}
		}
		.itemAppearActive {
			background-color: transparent;
		}
		.itemAppearDone {
			background-color: transparent;
		}
	}
	.item {
		padding: 0 0 13px 0;
		h6,
		p {
			font-size: 14px;
			margin: 0;
		}
	}

	.flexContainer {
		align-items: center;
		display: flex;
		justify-content: flex-start;
		width: 100%;
	}
}
