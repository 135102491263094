@import '../../../variables.scss';

.messages {
	position: fixed;
	margin: 5px 20px 20px 20px;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	:global {
		.ms-MessageBar {
			border: 2px solid $black_43;
			background-color: $grey_f7;
			box-shadow: 0px 2px 2px #00000029;
			border-radius: 5px;
			margin-top: 2px;
		}
		.ms-MessageBar--error {
			border-color: $red_c7;
			color: $red_c7;
			.ms-MessageBar-icon i {
				color: $red_c7;
			}
			.ms-MessageBar-dismissal {
				.ms-Button-icon {
					color: $red_c7;
				}
			}
		}
		.ms-MessageBar--success {
			border-color: $blue_0f;
			color: $blue_0f;
			.ms-MessageBar-icon i {
				color: $blue_0f;
			}
			.ms-MessageBar-dismissal {
				.ms-Button-icon {
					color: $blue_0f;
				}
			}
		}
		.ms-MessageBar--warning {
			border-color: $yellow_b1;
			color: $yellow_b1;
			.ms-MessageBar-icon i {
				color: $yellow_b1;
			}
			.ms-MessageBar-dismissal {
				.ms-Button-icon {
					color: $yellow_b1;
				}
			}
		}
		.ms-MessageBar-icon {
			margin-top: 11px;
			margin-left: 14px;
			i {
				font-size: 18px;
			}
		}
		.ms-MessageBar-text {
			margin-top: 12px;
			margin-left: 14px;
			margin-bottom: 12px;
		}
		.ms-MessageBar-innerText {
			cursor: pointer;
			font-size: 14px;
			font-weight: 600;
			width: 100%;
		}
		.ms-MessageBar-dismissal {
			margin-top: 4px;
			margin-right: 8px;
			.ms-Button-icon {
				font-size: 14px;
				height: 14px;
				line-height: 14px;
			}
		}
	}
}
